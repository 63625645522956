import React from "react";
import { useState } from "react";
import "./css/Accordion.css";

export default function Accordion() {
  const [selected, setselected] = useState(null);

  const toggle = (i) => {
    if (selected === i) {
      return setselected(null);
    }
    setselected(i);
  };

  return (
    <>
      <div className="FAQs">
          <div id="faqs">
              <h1>Frequent Asked Question</h1>
          </div>
            <div className="wrapper">
              <div className="accordion">
                {ques.map((item, i) => (
                  <div className="item">
                    <div className="title" onClick={() => toggle(i)}>
                      <h2>{item.question}</h2>
                      <span>{selected === i ? "<" : ">"}</span>
                    </div>
                    <div className={selected === i ? "content show" : "content"}>
                      <p>{item.answer}</p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
      </div>
    </>
  );
}

let ques = [
  {
    question: "Question 1",
    answer:
      "lorem45 4 4548d4548 88458 bhidhv hvusihdf vufdshfu gfuidsgfigyds fugdyigfdg fug udgfu sgfui gsfugdui fgdg fuiigsdfugduigfui gfu udugfui duvui dgu tsf7 gaiohs gfg uosjbch ay f dyufug dg fudgfu dfhdsyf gfdui sg481 54 894 556 487 hv hb",
  },
  {
    question: "Question 2",
    answer: "lorem45 4 4548d4548 88458 481 54 894 556 487 hv hb",
  },
  {
    question: "Question 3",
    answer: "lorem45 4 4548d4548 88458 481 54 894 556 487 hv hb",
  },
  {
    question: "Question 4",
    answer: "lorem45 4 4548d4548 88458 481 54 894 556 487 hv hb",
  },{
    question: "Question 5",
    answer: "lorem45 4 4548d4548 88458 481 54 894 556 487 hv hb",
  },{
    question: "Question 6",
    answer: "lorem45 4 4548d4548 88458 481 54 894 556 487 hv hb",
  }
];
