import React from "react";
import img1 from "../images/homeBackImg1.png"
import img2 from "../images/homeBackImg2.png"
import logo from "../images/Group11342.png"
import "./css/home.css"


function Home(){
    return (
        <>
        <div className="headBody"  id="home">
            <div className="intro">
                <img src={logo} alt="" id="logo" />  
                <h2 style={{fontSize:"200%"}}>Make Your Life Much Easier With FYC,S Ridess</h2>
                <p>ma sdjdfi hhh dsfhud fdgfuibjdb fhudfio </p>
                <button type="button" className="introBtn">Getting Setted</button>
                <button >Watch a Video</button>
            </div>
            <div className="headContainer">
                    <img src={img1} alt="" id="head-overlay"/ >
                    <img src={img2} alt="" id="head-background" / >
            </div>
        </div>
        </>
    )
};

export default Home;