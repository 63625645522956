import React from 'react'
import appStore from "../images/appStore.png"
import googleStore from "../images/googleStore.png"
import downloadImg1 from "../images/downloadImg1.png"
import downloadImg2 from "../images/downloadImg2.png"
import "./css/download.css"

function Download() {
  return (
    <>
        <div className="downloadSection" id='download'>
            <div className="downloadDesc">
                <h1>Download FYC's Rides App For</h1>
                <h1 style={{color:"rgba(57, 146, 180, 1)"}}>FREE</h1>
                <h7>For Faster, easier booking and exclusive deals.</h7><br />
                <div className='external-download-link'>
                    <button style={{marginTop:"30px" , width:"30%"}}>Download</button><br/>
                    <a href="https://play.google.com/store/games?hl=en" target="_blank" rel="noreferrer">
                        <img 
                            src={googleStore} 
                            alt=""  
                            // style={{ width:"30%" }} 
                            className="googleplay" 
                            />
                    </a>
                    <a href="https://www.apple.com/in/app-store/" target="_blank" rel="noreferrer" >
                        <img 
                            src={appStore} 
                            alt=""  
                            // style={{ width:"30%" }} 
                            className="appStore"
                        />
                    </a>
                </div>
            </div>
            <div className="downloadImg">
                <img src={downloadImg1} alt="" id="footer-overlay" />
                <img src={downloadImg2} alt="" id="footer-background" />
            </div>
        </div>
    </>
  )
}

export default Download
