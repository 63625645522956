import React from "react";
import Point from "./pointAdvantage";
// import Factors from "./advantageFactors";
import percentage from "../images/%.png"
import "./css/Advantages.css"
import shape from "../images/shape_3.png"

function Advantages() {
  return (
    <>
      <div className="advantage-flex-Container">
        <div className="pointsAdvantages flexitem">
          <p>FYC'S RIDES</p>
          {/* <br/> */}
          <h1>
            Best Advantage
            <br /> For You
          </h1>
          <Point detail={"lorem12 asjjn ashusn jgausbjdjnnj gdsydvhv dfjn g hjbg r grhbbhjv jbj f "} />
          <Point detail={"lorem12 asjjn ashusn jgausbjdjnnj gdsydvhv dfjn g hjbg r grhbbhjv jbj f "} />
          <Point detail={"lorem12 asjjn ashusn jgausbjdjnnj gdsydvhv dfjn g hjbg r grhbbhjv jbj f "} />
        </div>
        <div className="factorsAdvantage flexitem">
          <img src={shape} alt="" className="factorsShape"/>
          {keyFeatures.map((item , i) => (
              <div id={"keyItems"+i+1} className="factors">
                <h1>{item.acc} <img src={percentage} alt="" /></h1>
                <h4>{item.title}</h4>
                <p>{item.detail}</p>
              </div>
          ))}
        </div>
      </div>
    </>
  );
}

export default Advantages;


const keyFeatures=[
  {
    acc:"96",
    title:"FAST PICKUP",
    detail:"lorem14 sndsjdh hudehj efguibv dhdgfdiygsdh sdgdhsbdgdg"
  },{
    acc:"100",
    title:"SAFE GURARANTEE",
    detail:"lorem14 sndsjdh hudehj efguibv dhdgfdiygsdh sdgdhsbdgdg"
  },{
    acc:"92",
    title:"FAST PICKUP",
    detail:"lorem14 sndsjdh hudehj efguibv yyydhdgfdiygsdh sdgdhsbdgdg"
  }
]