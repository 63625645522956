import React from 'react'
import starImg1 from "../images/Shape.png"
import iPhoneimg2 from "../images/iPhone13.png"
import backimg3 from "../images/Group8.png"
import "./css/About.css"

function About() {
  return (
    <>
    <div className='about-container' id="about">
        <div className='about-container-header'>
            <h1>
                About
                <img src={starImg1} alt='' className='aboutStarImage'/ ><br/>
                &nbsp;&nbsp;&nbsp;FYCs RIDES
            </h1><br/><br/>
            <p>
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Molestiae, ad vitae. Porro nesciunt autem provident fugit aut vitae iusto temporibus dolores ipsam, quidem fuga iste.
            </p>
            <p>
                Lorem ipsum dolor sit amet consectetur, adipisicing elit. Ipsum consequuntur odio nesciunt praesentium autem dolores sit eaque facilis inventore neque sequi accusantium fugit doloremque quibusdam earum, vero mollitia vel reiciendis.f
            </p>
        </div>
        <div className='about-container-img'>
            <img src={backimg3} alt=''  className='about-container-background'></img>
            <img src={iPhoneimg2} alt='' className='about-container-overlay'></img>
        </div>
    </div>
    </>
  )
}

export default About
