import React from 'react'
import logo from "../images/Group11342.png"
import img1 from "../images/peopleImg/img1.png"
import img2 from "../images/peopleImg/img2.png"
import img3 from "../images/peopleImg/img3.png"
import img4 from "../images/peopleImg/img4.png"
import img5 from "../images/peopleImg/img5.png"
import img6 from "../images/peopleImg/img6.png"
import Card from './Card'
import "./css/need.css"

function Need() {
  return (
    <>
        <div className='need-header'>
            <div className='logo-container'>
                <img src={logo} alt="" className="logo" />
            </div>
                <h2>Why Rides With Fyc's Rides ?</h2>
        </div>
        <br />
        <br />
        <div className='whyFYC'>
                <div className="circleborder"></div>
                <Card img={img1} title={"Loream1"} detail={"lorem45 fjdief dfbug fgfu gfg gfyg"} /> 
                <Card img={img2} title={"Loream2"} detail={"lorem45 fjdief dfbug fgfu gfg gfyg"} /> 
                <Card img={img3} title={"Loream3"} detail={"lorem45 fjdief dfbug fgfu gfg gfyg"} /> 
                <Card img={img4} title={"Loream4"} detail={"lorem45 fjdief dfbug fgfu gfg gfyg"} /> 
                <Card img={img5} title={"Loream5"} detail={"lorem45 fjdief dfbug fgfu gfg gfyg"} /> 
                <Card img={img6} title={"Loream6"} detail={"lorem45 fjdief dfbug fgfu gfg gfyg"} />          
        </div>
    </>
  )
}

export default Need