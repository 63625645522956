import React from 'react'
import Home from "./home.js"
import About from './About.js';
import Advantages from './Advantages.js';
import Features from './features.js';
import Easy from './Easy.js';
import Need from './Need.js';
import Testimonial from './Testimonial.js';
import Accordion from './Accordion.js'
import Navbar from "./Navbar.jsx";
import Services from './Services.js';
import Contact from './Contact.js';
import Footer from './footer.jsx';
import Download from './Download.js';
import "../App.css"

function HomePage() {
  return (
    <>
        <section className='head'> 
            <Navbar />
            <Home /> 
    </section>
    <section>
          <Services />
          <About />
          <section className='backgray'>
              <Advantages />
          </section>
          <Features />
          <Need />
          <Easy />
          <Testimonial /> 
          <Accordion />
          <Contact />
    </section>
    <section className='downloadSec'>
          <Download />
    </section>
    <section className='footer'>
      <Footer />
    </section>
    </>
  )
}

export default HomePage;