import React from 'react'
import "./css/home.css"
import ServiceCards from './serviceCards'
import img from "../images/Background.png"

function Services() {
  return (
    <>
        <div className='serviceSection'>
            <div className="servicesHeader">
                <p>OUR AWESOME SERVICES</p>
                <h1>Best Car Services For You</h1>
            </div>
            <div className="servicePoints">
                <ServiceCards img={img} title={"lorem sahuh sjkdj"} detail={"lorem dji fgug fg fyd f ydgf fg dyfsybhd gf dsd f dgf svhdv yds fgd dgsgd g dsfd u"} />
                <ServiceCards img={img} title={"lorem sahuh sjkdj"} detail={"lorem dji fgug fg fyd f ydgf fg dyfsybhd gf dsd f dgf svhdv yds fgd dgsgd g dsfd u"} />
                <ServiceCards img={img} title={"lorem sahuh sjkdj"} detail={"lorem dji fgug fg fyd f ydgf fg dyfsybhd gf dsd f dgf svhdv yds fgd dgsgd g dsfd u"} />
            </div>
        </div>
    </>
  )
}

export default Services


