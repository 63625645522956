import React from 'react'
import logo from "../images/Group11342.png"
import Form from "./form"
import "./css/form.css"

function Contact() {
  return (
    <>
        <div className='contact-container' id='contact'>
            <div className="contact-details">
                <h1>Contact Us</h1>
                <p>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Illum, pariatur.</p>
                < Form />
            </div>
            <div className='contact-logo'>
                <img src={logo} alt="" id="contactLogoImg" />
            </div>
        </div>
    </>
  )
}

export default Contact