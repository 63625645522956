import React from 'react'

function Card(props) {
  return (
    <>
    <div class="card mb-3" style={{maxWidth: "540px"}}>
            <div class="row g-0">
                <div class="col-md-4">
                    <img src={props.img} class="img-fluid rounded-start" alt="..."/>
                </div>
                <div class="col-md-8">
                    <div class="card-body">
                        <h5 class="card-title">{props.title}</h5>
                        <p class="card-text">{props.detail}</p>
                    </div>
                </div>
            </div>
    </div>
    </>
  )
}

export default Card
