import React from 'react'
// import "./css/form.css"

function form() {
  return (
    <>
        <div class="createContactForm">
                <div class="form-container">
                    <form action="#" method="POST">
                        <div class="form-group">
                            <label for="email">Email:</label>
                            <input type="email" id="email" name="email" required />
                        </div>
                        <div class="form-group">
                            <label for="password">Password:</label>
                            <input type="password" id="password" name="password" required />
                        </div>
                        <div class="form-group submit-container">
                            <button type="submit">Submit</button>
                        </div>
                    </form>
                </div>
        </div>
    </>
  )
}

export default form;