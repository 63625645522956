import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { Pagination, Navigation } from 'swiper/modules';
import img1 from "../images/peopleImg/img7.png"
import img2 from "../images/peopleImg/img8.png"
import backImg1 from "../images/backImg3.png"
import backImg2 from "../images/backImg4.png"
import "./css/Testimoinal.css"
import Feedback from './feedback'

const Testimonial = () => {
  return (
    <>
      <div className="reviewHeader">
        <h5>Testimonials</h5>
        <h1>What People Say About US?</h1>
      </div>
      <div className='reviewData'>
            <img src={backImg1} alt="" id='reviewBackImg1'/>
            <Swiper
                slidesPerView={2.5}
                spaceBetween={30}
                loop={true}
                pagination={{
                  clickable: true,
                }}
                navigation={true}
                centeredSlides={true}
                modules={[Pagination, Navigation]}
                breakpoints={{
                // when window width is >= 640px

                    100: {
                      slidesPerView: 1,
                      spaceBetween: 20,
                    },
                    // when window width is >= 768px
                    768: {
                      slidesPerView: 1.5,
                      spaceBetween: 30,
                    },
                    // when window width is >= 1024px
                    1024: {
                      slidesPerView: 2.5,
                      spaceBetween: 30,
                    },
                  }}
                className="mySwiper"
              >

              {feedbacks.map((feedback)=>(
                <SwiperSlide>
                  <Feedback review={feedback} />
                </SwiperSlide>
              ))}
              
            </Swiper>
            <img src={backImg2} alt="" id='reviewBackImg2'/>
      </div>
    </>
  );
};

export default Testimonial;

const reviewdata = {
    rating:"5.0",
    img:img1,
    desciption:"I feel very secure when using caretails's services.Your customer care team is very enthusiastic and the driver is always on time",
    name:"Charlie Johnson",
    address:"From New York,US"
}

const feedbacks = [ 
  {
    rating:"5.0",
    img:img1,
    desciption:"I feel very secure when using caretails's services.Your customer care team is very enthusiastic and the driver is always on time",
    name:"Charlie Johnson",
    address:"From New York,US"
  },
  {
    rating:"5.0",
    img:img2,
    desciption:"I feel very secure when using caretails's services.Your customer care team is very enthusiastic and the driver is always on time",
    name:"Charlie Johnson",
    address:"From New York,US"
  },
  {
    rating:"5.0",
    img:img1,
    desciption:"I feel very secure when using caretails's services.Your customer care team is very enthusiastic and the driver is always on time",
    name:"Charlie Johnson",
    address:"From New York,US"
  },
  {
    rating:"5.0",
    img:img2,
    desciption:"I feel very secure when using caretails's services.Your customer care team is very enthusiastic and the driver is always on time",
    name:"Charlie Johnson",
    address:"From New York,US"
  },
]