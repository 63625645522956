import React from 'react'
import "./css/home.css"

function serviceCards(props) {
  return (
    <>
    <div className='servicePoint'>
        <div className="sectionPointImg">
            <img src={props.img} alt="" />
        </div>
        <div className="serviceCard">
            <h2>{props.title}</h2>
            <p>{props.detail}</p>
        </div>
    </div>
    </>
  )
}

export default serviceCards