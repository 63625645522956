import React from 'react'
import vector from "../images/Vector.png"
import "./css/Advantages.css"

function Point(props) {
  return (
    <>
        <div className='points'>
                <img src={vector} alt=''/>
                <p>{props.detail}</p>
        </div>
    </>
  )
}

export default Point;