import React,{useState} from "react";
import logo from "../images/Group11342.png"

import './css/Navbar.css';

const Header = () => {

const [isOpen, setIsOpen] = useState(false);

const toggleMenu = () => {
    setIsOpen(!isOpen);
};

    return (
      <nav className="navbar">
        <div className="navbar-logo">
          <img src={logo} alt="Logo" />
        </div>
        <button className="menu-toggle" onClick={toggleMenu}>☰</button>
        <ul className={`navbar-links ${isOpen ? 'open' : ''}`}>
          <li><a href="#home" className='activeShow'>Home</a></li>
          <li><a href="#about">About</a></li>
          <li><a href="#contact">Contact</a></li>
          <li><a href="#faqs">FAQS</a></li>
          <li><a href="#download" className="download-link">DOWNLOAD APP</a></li>
        </ul>
      </nav>
    );
  };
  
  export default Header;